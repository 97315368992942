/* Root variables (note: could not add these using the mui experimental_extendTheme API from mui)*/
/* Classes with MuiTypography-root are only applied to the mui <Typography> components*/

/* Global button styling */

.serviall-button {
    border-radius: 10px !important;
    background-color: var(--mui-palette-serviall-main) !important;
    color: var(--mui-palette-common-background) !important;
    padding: 5px 10px 5px 10px !important;
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: var(--serviall-h6) !important;
    min-height: 51.6px;
    text-transform: none !important;
}


.serviall-button-success {
    border-radius: 10px !important;
    background-color: var(--mui-palette-success-dark) !important;
    color: var(--mui-palette-common-background) !important;
    padding: 5px 10px 5px 10px !important;
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: var(--serviall-h6) !important;
    min-height: 51.6px;
    text-transform: none !important;
}

.serviall-button-error {
    border-radius: 10px !important;
    background-color: var(--mui-palette-error-dark) !important;
    color: var(--mui-palette-common-background) !important;
    padding: 5px 10px 0px 10px !important;
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: var(--serviall-h6) !important;
    min-height: 51.6px;
    text-transform: none !important;
}

.serviall-button-success.Mui-disabled {
    background-color: var(--mui-palette-serviall-darkGray) !important;
}

.serviall-button-error.Mui-disabled {
    background-color: var(--mui-palette-serviall-darkGray) !important;
}

.serviall-button.Mui-disabled {
    background-color: var(--mui-palette-serviall-darkGray) !important;
}

.serviall-datagrid-loading-container {
    display: flex;
    min-height: 300px;
    max-height: 500px;
    height: 50vh;
    width: 100%;
    justify-content: center;
    align-items: center
}

.serviall-datagrid-container {
    display: flex;
    flex-direction: column;
    height: 35em;
    width: fit-content;
    max-width: 100%;
}

.serviall-datagrid {
    border-radius: 10px !important;
    border-width: 2px !important;
    border-color: #E6E6E7;
    height: 500px;
    width: fit-content;
    max-width: 100%;
    overflow-x: auto;
    /* margin-left: 6vw; */
}

.serviall-datagrid-header1 {
    background-color: var(--mui-palette-serviall-gray1);
    color: var(--mui-palette-common-background) !important;
    font-weight: normal;
    font-family: "Poppins";
}

.serviall-datagrid-header2 {
    background-color: var(--mui-palette-serviall-darkGray);
    color: var(--mui-palette-common-background) !important;
    font-weight: bold !important;
}


.serviall-datagrid-header1 .rdg-header-sort-name,
.serviall-datagrid-header2 .rdg-header-sort-name {
    display: flex;
    justify-content: center;
}

.serviall-datagrid-colFilter-text.MuiTypography-root {
    /* overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis; */
    margin-top: 4px;
    font-size: var(--serviall-h6);
}

.serviall-datagrid-colFilter-input {
    height: 30px
}

.serviall-datagrid-corner1 {
    border-radius: 10px 0px 0px 10px;
}

.serviall-datagrid-corner2 {
    border-radius: 0px 10px 10px 0px;
}

.serviall-datagrid-row1 {
    background-color: var(--mui-palette-serviall-lightGray);
    font-family: "Poppins";
    font-size: small;
}

.serviall-datagrid-row2 {
    background-color: var(--mui-palette-common-background);
    font-family: "Poppins";
    font-size: small;
}

.serviall-subtitle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.serviall-color-main {
    color: var(--mui-palette-serviall-main) !important;
}

.serviall-color-success {
    color: var(--mui-palette-success-main) !important;
}

.serviall-color-warning {
    color: var(--mui-palette-warning-light) !important;
}

.logo {
    width: auto;
    height: 180px;
    padding: 36px;
}

.icon-space-div {
    width: 24px;
    height: 24px;
}


.serviall-back-text.MuiTypography-root {
    cursor: pointer;
    width: fit-content;
}

/* Color classes */
.serviall-main-color {
    color: var(--mui-palette-serviall-main) !important;
}

.serviall-gray1 {
    color: var(--mui-palette-serviall-gray1);
}

.serviall-gray2 {
    color: var(--mui-palette-serviall-gray2);
}

.serviall-darkGray {
    color: var(--mui-palette-serviall-darkGray);
}

/* Para centrar los checkbox de la tabla */
.rdg-checkbox-label {
    display: flex;
    justify-content: center;
}

.rdg-row {
    background-color: var(--mui-palette-serviall-gridRow);
}


.react-calendar__month-view__weekdays {
    text-align: center;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0;
}

.serviall-page-title1.MuiTypography-root {
    font-size: var(--serviall-h3);
    font-weight: 500;
    color: var(--mui-palette-serviall-main);
}

.serviall-page-title2.MuiTypography-root {
    font-size: var(--serviall-h6);
    font-weight: 500;
    color: var(--mui-palette-serviall-gray1);
}

.serviall-page-title2-alt.MuiTypography-root {
    font-size: var(--serviall-h6);
    font-weight: 500;
    color: var(--mui-palette-serviall-main);
}

.serviall-page-caption.MuiTypography-root {
    font-size: var(--serviall-h6);
    height: 51.6px;
    font-weight: 500;
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--mui-palette-serviall-gray1);
}

.serviall-page-caption.MuiTypography-root:hover {
    cursor: pointer;
}

.serviall-h1.MuiTypography-root {
    font-size: var(--serviall-h1);
}

.serviall-h2.MuiTypography-root {
    font-size: var(--serviall-h2);
}

.serviall-h3.MuiTypography-root {
    font-size: var(--serviall-h3);
}

.serviall-h4.MuiTypography-root {
    font-size: var(--serviall-h4);
}

.serviall-h5.MuiTypography-root {
    font-size: var(--serviall-h5);
}

.serviall-h6.MuiTypography-root {
    font-size: var(--serviall-h6);
}

.serviall-small.MuiTypography-root {
    font-size: var(--serviall-small);
}

.rs-picker-toggle,
.rs-picker-toggle-active,
.rs-btn,
.rs-btn-default,
.rs-btn-sm {
    border-radius: 0px !important;
}

.serviall-button-reset {
    border-radius: 10px !important;
    background-color: var(--mui-palette-serviall-main) !important;
    color: var(--mui-palette-common-background) !important;
    padding: 10px !important;
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: var(--serviall-h6) !important;
    min-height: 51.6px;
    align-self: flex-end;
}

.react-calendar {
    width: 100% !important;
    min-width: 300px;
}



@media screen and (min-width: 1280px) {
    .grid-separator {
        border-right: solid;
        border-width: 2px;
        border-color: var(--mui-palette-serviall-gray2)
    }
}


.Container-wrapper {
    background-color: var(--mui-palette-serviall-datagridGroupBackground) !important;
}

.MuiInputBase-root .MuiTypography-root,
.MuiInputBase-root input {
    font-size: 16px !important;
    font-family: "Poppins" !important;
    display: flex;
    align-items: center;

}

.serviall-input .MuiSelect-select {
    padding: 0 1px !important;
}

.dashboard-outlet-container .MuiInputBase-root:focus-within,
.addAuxItemDialog-container .MuiOutlinedInput-root:focus-within {
    box-shadow: 0px 0px 3px 3px var(--mui-palette-serviall-main);
}

.MuiInputBase-root::before {
    border: none;
    outline: none;
}

.dashboard-outlet-container .MuiOutlinedInput-root,
.dashboard-outlet-container .MuiOutlinedInput-notchedOutline,
.addAuxItemDialog-container .MuiOutlinedInput-root,
.addAuxItemDialog-container .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.calendarioPage-navigation-button .MuiInputBase-root,
.calendarioPage-navigation-button .MuiOutlinedInput-notchedOutline {
    border: none !important;
    box-shadow: none !important;
}

.configuracionMantenedoresVersiones-container .MuiOutlinedInput-root,
.serviall-column-selector .MuiOutlinedInput-root {
    border: 1px solid var(--mui-palette-serviall-main) !important;
}

.datagridPagination {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-bottom: 1px solid var(--mui-palette-TableCell-border) !important;
    width: fit-content;
    margin-left: auto;
    height: fit-content;
    margin-right: 0;
}

.MuiTableCell-root {
    border-bottom: none !important;
}

.datagridPagination-labels .MuiTablePagination-selectLabel {
    padding: 0 !important;
    margin: 0 !important
}

.configuracion-permisos-section-container .MuiOutlinedInput-root,
.configuracion-permisos-section-container .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--mui-palette-serviall-main) !important;
}

.maintainersModelosDialog-container .MuiInputBase-root {
    border: 1px solid var(--mui-palette-serviall-main) !important;
    border-radius: 10px !important;
}
